.icons-div a:visited {
  color: #000;
}

.main.container {
    margin-top: 20px;
    margin-bottom: 60px;
}
.panel-collapse {
  display: block !important;
}

.submit-button {
    margin-top: 24px !important;
    float: right;
}

.data {
    margin-top: 15px;
}

thead tr th {
    background: #e0e0e0 !important;
}
ui.inverted.dimmer {
    background-color: rgba(255,255,255,0) !important;
}

body > .grid {
    height: 100%;
}
.column {
    max-width: 450px;
    margin-top: 300px;
}

.form {
    padding-bottom: 40px;
}

.search-custom {
      width: 33%;
      max-width: 100%;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
    border-top-color: rgba(34, 36, 38, 0.15);
    border-right-color: rgba(34, 36, 38, 0.15);
    border-bottom-color: rgba(34, 36, 38, 0.15);
    border-left-color: rgba(34, 36, 38, 0.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.search-custom-year {
  width: 10%;
  max-width: 100%;
outline: 0;
-webkit-tap-highlight-color: rgba(255,255,255,0);
text-align: left;
line-height: 1.21428571em;
font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
padding: .67857143em 1em;
background: #fff;
border: 1px solid rgba(34,36,38,.15);
border-top-color: rgba(34, 36, 38, 0.15);
border-right-color: rgba(34, 36, 38, 0.15);
border-bottom-color: rgba(34, 36, 38, 0.15);
border-left-color: rgba(34, 36, 38, 0.15);
color: rgba(0,0,0,.87);
border-radius: .28571429rem;
-webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
transition: border-color .1s ease,-webkit-box-shadow .1s ease;
transition: box-shadow .1s ease,border-color .1s ease;
transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
-webkit-box-shadow: none;
box-shadow: none;
}

.full-width {
  min-width: 100%;
}

.img-usa {
  max-width:160px;
}

.margin-left {
  margin-left: 20px;
}

.other-div a {
  color: '#EC4137' !important;
}
  
  @media only screen and (max-width: 680px) {
    .icon {
      width: 38px !important;
      padding-left: 5px !important;
    }
    .other-div {
      margin-top: 13px !important;
    }
    .icons-div {
      max-width: 282px !important;
    }
    .rol {
      width: 29px !important;
      margin-top: 2px;
    }
    .ciez {
      width: 66px !important;
      margin-top: 2px;
    }
    .img-usa {
      max-width:100px;
    }
    .search-custom-year { 
      width: 27%;
    }
  }
  @media only screen and (min-width: 1200px) {
.ui.container {
    width: 1456px;
    margin-left: auto!important;
    margin-right: auto!important;
}
  }
  .ui.menu .item {
    -ms-flex: auto !important;
    flex: auto !important;
    padding: .92857143em 0.243em;
  }
  .red {
    font-size: 11px !important;
  }

  a {
    text-decoration: none;
  }

  .search-custom::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000 !important;
    opacity: 1; /* Firefox */
  }
  
  .search-custom:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000 !important;
  }
  
  .search-custom::-ms-input-placeholder { /* Microsoft Edge */
    color: #000 !important;
  }
  .search-custom {
    border-color: #000 !important;
    border-radius: 0 !important;
  }

  .search-custom-year::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000 !important;
    opacity: 1; /* Firefox */
  }
  
  .search-custom-year:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000 !important;
  }
  
  .search-custom-year::-ms-input-placeholder { /* Microsoft Edge */
    color: #000 !important;
  }
  .search-custom-year {
    border-color: #000 !important;
    border-radius: 0 !important;
  }
  .nav-link {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }
  @media only screen and (min-width: 800px) {
    .nav-link {
      padding: 0 20px 35px 20px !important;
    }

    .img-usa {
      max-width:80px;
    }
  }

  
  .ql-container {
    min-height: 500px;
}